import React , { useEffect, useRef, useState }from 'react';
import { useHistory } from 'react-router-dom';
import { DataGrid , frFR} from '@mui/x-data-grid';
import { Toolbar , Grid, CircularProgress } from '@mui/material';
import Container from '@material-ui/core/Container';
import {Modal} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import axios from 'axios'; // Import Axios for API calls
import Delete from '../../images/TrashBin.png';
import "../../style/UserDataTable.css";
import '../../style/dashboard.css';  // Import the generated CSS file
import moment from 'moment';
import { CSVLink } from 'react-csv';


function UserList(props) {
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(false);
    const [deleteloading, setdeleteloading] = useState(false);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    function fetchdata(){
      setloading(true)

      axios.get('users/')
      .then(async (response) => {
        setloading(false)
          setData(response.data);
          console.log(response.data);
      })
      .catch((error) => {
        setloading(false)
          console.error('Error fetching data:', error);
      });
    }
    useEffect(() => {
        // Fetch data from an API using Axios
       fetchdata()
           
    }, []);
    function mapGenderToFrench(gender) {
      switch(gender) {
        case 'male':
          return 'homme';
        case 'female':
          return 'femme';
        case 'other':
          return 'autre';
        default:
          return gender;  // Keep the original value if it doesn't match 'male', 'female', or 'other'
      }
    }
    const handleOpen = (userId) => {
        const urlWithParam = `${window.location.pathname}?userId=${userId}`;
        window.history.pushState({}, '', urlWithParam);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      
      const csvData =data && data.length>0 ? data.map(row => ({
        ID: row._id||'N/A' ,
        Prénom:  row.first_name || 'N/A',
        Nom:  row.last_name || 'N/A',
        'Sexe':mapGenderToFrench(row.gender)|| 'N/A',
        'Date de naissance':moment(row.birth_date).format("DD/MM/YYYY")|| 'N/A',
        'Etat civil':row.marital_status|| 'N/A',
        'Nationalité':row.nationality|| 'N/A',
        'Rue/Avenue':row.street|| 'N/A',
        'Quartier':row.address|| 'N/A',
        'Ville':row.city|| 'N/A',
        'Commune':row.commune|| 'N/A',
        'Pays': row.country|| 'N/A',
        'Métier': row.job|| 'N/A',
        'Secteur d`activité': row.job_sector|| 'N/A',
        'Adresse e-mail': row.email|| 'N/A',
        "Numéro de téléphone":  row.phone_number || 'N/A',
        'Date d’inscription': moment(row.createdAt).format('DD/MM/YYYY, HH:mm')|| 'N/A',
        'Statut':row.status=='WAITING_FOR_VALIDATION' || row.status=='WAITING_FOR_COMPLETION' ?"Non finalisé":row.status,

      })) : [];
    const handleDeleteUser = () => {
            // Get the URLSearchParams object for the current URL
    const searchParams = new URLSearchParams(window.location.search);
    if(deleteloading)
      return;

    const userId = searchParams.get('userId');
    setdeleteloading(true)
        // Send an Axios DELETE request to the server to delete the user
        axios.delete(`users/${userId}`)
          .then((response) => {
            setData(data.filter((item) => item._id !== userId));
            handleClose();
            fetchdata()
            history.push(`/UserList`);
          })
          .catch((error) => {
            alert('Une erreur est survenue', error);
            setdeleteloading(false)
            handleClose();
          });
          
      };
    function handleClick(id) {
        // Handle the row click event, e.g., navigate to a user profile page
        history.push(`/UserDetails/${id}`);
        // You can use a router like React Router to navigate to another page.
      };
      const handleCellClick = (params, event) => {
        const isActionColumn = params.field === 'action';
    
        if (isActionColumn) {
          // Prevent row click action for the 'action' column
          event.stopPropagation();
          handleOpen(params.row._id); // Perform the action for the 'action' column
        } else {
          // Navigate to the desired page for non-action clicks
          history.push(`/UserDetails/${params.row._id}`);
        }
      };

    const columns = [
        {
            field: '_id',
            headerName: 'ID',
            width:80, // Adjust the percentage as needed
            renderCell: (params) => (
                 <div style={{ cursor: 'pointer'}} >{params.row._id.slice(19,24).toUpperCase()}</div>
              
            ),
        },
        {
          field: 'first_name',
          headerName: 'Prénom',
          flex: 1, // Adjust the percentage as needed
      },
        {
            field: 'last_name',
            headerName: 'Nom',
            flex: 1, // Adjust the percentage as needed
        },
        
        {
            field: 'phone_number',
            headerName: 'Numéro de téléphone',
            flex: 3/3.5, // Adjust the percentage as needed
        },
        {
            field: 'email',
            headerName: 'E-mail',
            flex: 1.2, // Adjust the percentage as needed
        },
        {
          field: 'createdAt',
          headerName: 'Date d’inscription',
          width:150, // Adjust the percentage as needed
          renderCell: (params) => (
            <div>{moment(params.row.createdAt).format('DD/MM/YYYY, HH:mm')}</div>
        ),
      },
      {
        field: 'status',
        headerName: 'Statut',
        width:100,// Adjust the percentage as needed
        renderCell: (params) => (
          <div>{params.row.status=='WAITING_FOR_VALIDATION' || params.row.status=='WAITING_FOR_COMPLETION' ?"Non finalisé":params.row.status}</div>
      ),
    },
        {
            field: 'action',
            headerName: '',
            width:70,
            renderCell: (params) => (
                <div className="actionn">
                    <button
                        type="button"
                        className="delete_btn"
                        onClick={() => handleOpen(params.row._id,params.row.first_name,params.lastname)}
                        disabled={params.row.status === 'Supprimée'} 
                    >
                        <img className="Delete" src={Delete} alt="Delete" style={{ opacity: params.row.status === 'Supprimée' ? '0.3' : 'inherit' }}/>
                    </button>
                </div>
            ),
        },
    ];

    return (
      <Container className="container">
               <Toolbar>
                
      <Grid container >
        <Grid item xs={8}>
          <Typography variant="h5" component="div" className="Username_title">
          Utilisateurs<span class="badge">{data.length}</span>
          </Typography>
        </Grid>
       
     </Grid>
   <div style={{width:170}}>
     <CSVLink
          data={csvData}
          filename={`users_list.csv`}
          style={{color:'#046D93'}}
        >
          Télécharger la liste
        </CSVLink>
        </div>  
    </Toolbar>
   
            <DataGrid
                className="data_grid"
                rows={data}
                columns={columns}
                autoHeight
                pageSize={10}
                loading={loading}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id} // Use the "_id" field as a custom id
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                onCellClick={handleCellClick}
            />
        
        <Modal open={open} onClose={handleClose}>
        <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 684,p: 2 , borderRadius:15 ,padding:30}}>
          <Typography variant="h4">Supprimer l’utilisateur</Typography>
          <Typography
             style={{
              color:'#4B706C'
            }}
          >Êtes vous sur de vouloir supprimer l’utilisateur ? </Typography>
          <div style={{paddingTop:12}}>
            <Button variant="contained" onClick={handleDeleteUser}
                  style={{
                    borderRadius: 15,
                    backgroundColor:'#0487B6',
                    padding:15,
                    fontFamily: 'Outfit',
                  }}
                  
            >Supprimer  { deleteloading && <CircularProgress style={{marginLeft:10}} color='inherit' size="1rem" />}</Button>
            
            <Button onClick={handleClose}
                              style={{
                                borderRadius: 15,
                                backgroundColor:'#EAF6FA',
                                padding:15,
                                marginLeft:12,
                                fontFamily: 'Outfit',
                              }}
            >Annuler</Button>
          </div>
        </Paper>
      </Modal>
        </Container>
    );
}

export default UserList;
