import React , { useEffect, useState }from 'react';
import CustomModal from '../../components/modal/modal'; // Import the modal component
import { useHistory } from 'react-router-dom';
import axios from 'axios'; // Import Axios for API calls
import { DataGrid , frFR} from '@mui/x-data-grid';
import {Typography ,Toolbar ,Stack, Button, Grid } from '@mui/material';
import Delete from '../../images/TrashBin.png';
import listIcon from '../../images/participantlist.png';
import "../../style/UserDataTable.css";
import moment from 'moment';
import Container from '@material-ui/core/Container';
function SurveyList(props) {
    const [data, setData] = useState([]);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem('accessToken');
    const [loading, setloading] = useState(false);
    const [deleteloading, setdeleteloading] = useState(false);
    // Fetch data here (you can use the Fetch API or axios)
    useEffect(() => {
      const fetchData = async () => {
        setloading(true)
        try {
          const surveys= (await axios.get('surveys/')).data;
      
          surveys.result = surveys.result.map((elt)=>{
            return {
               ...elt,
               reponses:surveys.participations?.find((p)=>p._id==elt._id)?.finishedCount
            }
          })
          setData(surveys.result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        setloading(false)
      };
      fetchData();
    }, [token]);
     console.log(data)
      // Prepare Fetched data for MUI datagrid here 
    const columns = [
       {
        field: '_id',
        headerName: 'ID',
        width:80, // Adjust the percentage as needed
        renderCell: (params) => (
             <div onClick={() => handleOpenSurveyClick(params.row._id)}   style={{ cursor: 'pointer'}} >{params.row._id.slice(19,24).toUpperCase()}</div>
          
        ),
      },
      { field: 'title', headerName: 'Nom de l‘enquête', flex : 2.7},
      { field: 'points', headerName: 'Points', width:60},
      { field: 'reponses', headerName: 'Réponses',width:80, },
      { field: 'maskedCount', headerName: 'Masquées',width:80,
      renderCell: (params) => {  return(
        <div >{params.row?.maskedCount}</div>
     
   )},
     },
      { field: 'start_at', headerName: 'Date de publication',flex : 1},
      { field: 'end_at', headerName: 'Date de fin',flex :1 },
      {
        field: 'status',
        headerName: 'Statut',
        width:80,
        renderCell: (params) => (
          <div className={`status ${params.value === 'SUPPRIME' ? 'SUPPRIME' : ''}`}>
            {params.value}
          </div>
        ),
      },
      {
        field: 'action',
        headerName: '',
        width:100,
        renderCell: (params) => (
            <div className="action" >
                <button type="button" className="delete_btn" onClick={() => handleOpen(params.row._id)} disabled={params.row.status === 'Supprimée'} >
                    <img className="Delete" src={Delete} alt="Delete" style={{ opacity: params.row.status === 'Supprimée' ? '0.3' : 'inherit' }} />
                </button>
                <button type="button" className="delete_btn" onClick={() => history.push(`/participants/${params.row._id}`)} >
                    <img className="Delete" src={listIcon} alt="Delete" />
                </button>
            </div>
        ),
    },
    ];
  // Step 2: Count the participants for each row and create rows array
  const rows = data.map((survey, index) => ({
    ...survey,
    _id: survey._id,
    title: survey.title,
    points: survey.points,
    participants: survey?.participants?.length,  // Assuming 'participants' is an array in your survey object
    start_at:survey.start_at ?moment(survey.start_at).format('DD/MM/YYYY - HH:mm'):"",
    end_at:survey.end_at ?  moment(survey.end_at).format('DD/MM/YYYY - HH:mm'):"",
    status : survey.status
  }));
  
  const handleOpen = (SurveyId) => {
        const urlWithParam = `${window.location.pathname}?SurveyId=${SurveyId}`;
        window.history.pushState({}, '', urlWithParam);
        setOpen(true);
      };
  const handleClose = () => {setOpen(false);};
  const handleDelete = () => { 
            // Get the URLSearchParams object for the current URL
  const searchParams = new URLSearchParams(window.location.search);
  const SurveyId = searchParams.get('SurveyId');
        // Send an Axios DELETE request to the server to delete the user
        setdeleteloading(true)
        axios.delete(`surveys/remove/${SurveyId}`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            setdeleteloading(false)
            setData(data.filter((item) => item._id !== SurveyId));
            handleClose();
            history.go(0);
          })
          .catch((error) => {
            setdeleteloading(false)
            alert(error);
            handleClose();
          });
          
      };

  const handleOpenSurveyClick = (id) => {
        // Handle the row click event, e.g., navigate to a user profile page
        history.push(`/SurveyDetails/${id}`);
        // You can use a router like React Router to navigate to another page.
      };

      const handleCellClick = (params, event) => {
        const isActionColumn = params.field === 'action';
    
        if (isActionColumn) {
          // Prevent row click action for the 'action' column
          event.stopPropagation();
          handleOpen(params.row._id); // Perform the action for the 'action' column
        } else {
          // Navigate to the desired page for non-action clicks
          history.push(`/SurveyDetails/${params.row._id}`);
        }
      };

    return (
      <Container className="container">
    <Toolbar>
      <Grid container >
        <Grid item xs={8}>
          <Typography variant="h5" component="div" className="Username_title">
          Enquêtes<span class="badge">{data.length}</span>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} style={{float:"right" }}>
          <Button style={{ backgroundColor:'#0487B6',color:'#fff',height: 50, borderRadius: 15, border: '3px solid #0487B6', fontFamily: 'Outfit' }} onClick={() => history.push('/AddSurvey')} >
          Ajouter une enquête
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>

            <DataGrid
                className="data_grid"
                rows={rows}
                columns={columns}
                autoHeight
                pageSize={10}
                loading={loading}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id} // Use the "_id" field as a custom id
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                onCellClick={handleCellClick}
            />
{/* MODAL START */}
<CustomModal
        open={open}
        handleClose={handleClose}
        loading={deleteloading}
        handleAction={handleDelete}
        title="Supprimer l'enquete"
        content={`Êtes vous sur de vouloir supprimer l'enquetes ?`}
        button="Supprimer"
      />
{/* MODAL END */}
        </Container>
    );
}

export default SurveyList;
